<template>
    <section class="pb-5 pt-5">
        <v-container grid-list-md light text-xs-center>
            <v-layout align-center row wrap>
                <v-flex xs12>
                    <div class="text-xs-center ma-5 pt-5 pb-5" align="center">
                        <div class="flex display-1 font-weight-light pb-3">Online Ordering Solutions
                        </div>
                        <div class="flex subheading font-weight-light">
                          Enhance the efficiency of managing online orders with a dedicated in-store terminal compatible with any POS. Provide your customers with an improved user experience, all while eliminating commission fees. Say goodbye to third-party vendors for good
                        xs</div>
                    </div>
                </v-flex>
                <v-flex xs12 md3>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Local Website Development Design" contain src="/img/online-ordering-restaurants/restaurant-customer-online-order.jpg">
                        </v-img>
                        <v-card-title class="justify-center">Place Order</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Your customer places an order online on your website, IOS or Android mobile app.
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 md3>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Local Website Development Design" contain src="/img/online-ordering-restaurants/customer-prepare-meal.jpg">
                        </v-img>
                        <v-card-title class="justify-center">Prepare Order</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                          Fulfill the order details and prepare the order for pickup, delivery or curbside.
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 md3>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Customer Engagement" contain src="/img/online-ordering-restaurants/restaurant-takeout-order.jpg">
                        </v-img>
                        <v-card-title class="justify-center">Mark Complete</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                          The customer receives the curbside, delivery or in-store order.
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 md3>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Integrated Marketing POS System" contain src="/img/online-ordering-restaurants/restaurant-retargeting.jpg">
                        </v-img>
                        <v-card-title class="justify-center">Keep 'em Coming Back</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Target existing customers with push notifications and SMS messages.
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </section>
</template>

<script>
    export default {
        name: "RestaurantOnlineOrders"
    }
</script>

<style scoped>

</style>
