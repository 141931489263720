<template>
    <section class="white pb-5 pt-5">
        <v-container grid-list-md light text-xs-center>
            <v-layout align-center row wrap>
                <v-flex xs12>
                    <div class="text-xs-center ma-5 pt-5 pb-5" align="center">
                        <div class="flex display-1 font-weight-light pb-3">Y2POS Features
                        </div>
                        <div class="flex subheading font-weight-light">
                            Today’s business have never had more demand or opportunities—to reach their customers in new and exciting digital way.
                        </div>
                    </div>
                </v-flex>
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Integrated Marketing POS System" contain src="/img/restaurant-online/restaurant-online-ordering-dashboard.jpg">
                        </v-img>
                        <v-card-title class="justify-center">Back-Office</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Manage every aspect of your restaurant through a single dashboard. Update menus, promotions, pricing, delivery zones across all channels including in-store POS, IOS, Android, and Web Apps.
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Integrated Marketing POS System" contain src="/img/restaurant-online/restaurant-menu.jpg">
                        </v-img>
                        <v-card-title class="justify-center">Menu & Promotions</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Your restaurant menu is synced with the POS back office which allows for effortless menu updates. All orders are sent directly to your in-store online ordering terminal.                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Customer Engagement" contain src="/img/restaurant-online/restaurant-dashboard-online.jpg">
                        </v-img>
                        <v-card-title class="justify-center">Ordering Process</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Customers can easily place orders, view menu items, see pricing, leave feedback, collect loyalty and more. "86" items are removed from the website and mobile apps in real-time.
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Local Website Development Design" contain src="/img/restaurant-online/restaurant-websites.jpg">
                        </v-img>
                        <v-card-title class="justify-center">Branded Restaurant Website</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Your restaurant website displays your current menu, promotions, hours of operations, locations, and more. No more wasted time having to update your website separately.
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Integrated Marketing POS System" contain src="/img/restaurant-online/restaurant-delivery-software.jpg">
                        </v-img>
                        <v-card-title class="justify-center">Multi-Unit Support</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            In the case of multi-unit restaurants, customers can view all locations and select the convenient one. All menu items, prices, delivery zones, are configurable in the back office.
                        </v-card-text>
                    </v-card>
                </v-flex>

                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Customer Engagement" contain src="/img/restaurant-online/restaurant-order-details.jpg">
                        </v-img>
                        <v-card-title class="justify-center">Schedule Orders</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Customers can schedule orders for immediate processing or for a specified date and time in the future. The checkout process allows customers to send special requests.
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Integrated Marketing POS System" contain src="/img/restaurant-online/restaurant-customer-experience.jpg">
                        </v-img>
                        <v-card-title class="justify-center">Customer Activity</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Each customer has own profile connected with his phone number, email address and contact information. Customers can view order history, save payment methods and more.
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Local Website Development Design" contain src="/img/restaurant-online/restaurant-customer-orders.jpg">
                        </v-img>
                        <v-card-title class="justify-center">Customer Checkout</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Checking out made easy, the checkout process works flawlessly across all devices. Customers can schedule delivery, in-store and take-out orders for now or in the future.
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Local Website Development Design" contain src="/img/restaurant-online/restaurant-digital-ordering-online.jpg">
                        </v-img>
                        <v-card-title class="justify-center">Online Ordering</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Customer orders from the web and mobile apps are sent directly to a dedicated in-store online ordering terminal that is conveniently located at your "To-Go" station.
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Local Website Development Design" contain src="/img/restaurant-online/restaurant-delivery-inhouse.jpg">
                        </v-img>
                        <v-card-title class="justify-center">Delivery Zones</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Create delivery zones with different fees based on the proximity of the user to the restaurant. Customers can place delivery orders if the address is in your delivery zone.
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Customer Engagement" contain src="/img/img/analytics.jpg">
                        </v-img>
                        <v-card-title class="justify-center">Analytics & Reporting</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            100+ reports are available to help you manage your locations, control costs, labor, and inventory. Track sales across all channels including sales trends, snapshots, and projections.
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex md4 xs12>
                    <v-card class="elevation-0 transparent">
                        <v-img alt="Customer Engagement" contain src="/img/restaurant-online/restaurant-mobile-apps-web.jpg">
                        </v-img>
                        <v-card-title class="justify-center">IOS, Web & Android Mobile Apps</v-card-title>
                        <v-card-text class="grey--text  text--darken-2 font-weight-light">
                            Customer orders from the web and mobile apps are sent directly to a dedicated in-store online ordering terminal that is conveniently located at your "To-Go" station for easy processing.                           </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </section>
</template>

<script>
    export default {
        name: "RestaurantOnlineOrders"
    }
</script>

<style scoped>

</style>
